<template>
  <div class="lg:text-xl home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeaderBH />
    <div class="spacer lg:pt-24 pt-8"></div>
    <div class="bg-sub-banner relative">
      <div class="bg-banner lg:block absolute bottom-0 right-0 z-30 hidden w-2/5 h-full bg-no-repeat bg-contain"></div>
      <carousel :per-page="1" :navigate-to="someLocalProperty" :mouse-drag="true" pagination-padding="10"
        :pagination-enabled="false" pagination-active-color="#5a1e78" pagination-color="#e6c8f0" :loop="true"
        :autoplay="true" autoplay-timeout="3000">
        <slide class="lg:pt-0 md:text-left relative pt-12 overflow-hidden text-center text-gray-800">
          <div class="lg:py-20 max-w-screen-xl px-4 pt-16 mx-auto">
            <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
              <div class="lg:w-3/4 w-full px-4 overflow-hidden">
                <div class="lg:py-24">
                  <h3 class="lg:text-7xl text-sitePurple text-4xl font-semibold leading-tight uppercase">Lebih Dari 35
                    Tahun</h3>
                  <h3 class="lg:text-5xl lg:mb-8 text-sitePurple mb-5 text-3xl font-light leading-tight uppercase">
                    Pengalaman Dalam Bedah Plastik</h3>
                  <h2 class="lg:font-light lg:mb-3 lg:text-4xl mb-2 text-2xl leading-tight">Dr Marco, Ahli Bedah Plastik
                    & Kosmetik di <br class="lg:block hidden">Singapura Mount Elizabeth Novena</h2>
                  <p class="lg:text-xl mb-2">Pengantar Bedah Plastik Endoskopi & Robot Pengencangan Perut</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="lg:pt-0 md:text-left relative pt-12 overflow-hidden text-center text-gray-800">
          <div class="lg:py-20 max-w-screen-xl px-4 pt-16 mx-auto">
            <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
              <div class="lg:w-3/4 w-full px-4 overflow-hidden">
                <div class="lg:py-24">
                  <h3 class="lg:text-7xl text-sitePurple lg:mb-8 mb-5 text-4xl font-semibold leading-none uppercase">
                    Mengutamakan Pasien, Selalu</h3>
                  <h2 class="lg:font-light lg:mb-3 lg:text-4xl mb-2 text-2xl leading-tight">Dr Marco, Ahli Bedah Plastik
                    & Kosmetik di Singapura Mount Elizabeth Novena</h2>
                  <p class="lg:text-xl mb-2">Mencapai tujuan pasien dengan teknik yang aman</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
      <img class="lg:hidden" src="images/bg-banner.png">
    </div>
    <section class="text-gray-800">
      <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
        <h3 class="md:mb-5 lg:text-4xl lg:mb-12 mb-8 text-3xl font-semibold leading-tight text-center uppercase">Tentang
          Dr Marco</h3>
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="lg:ml-auto mb-4" src="images/image-doctor.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <p class="lg:text-2xl mb-2 text-xl font-bold">Dr Marco Faria Correa</p>
              <p class="mb-8">
                Dip Dokter. (Rio Grande do Sul), Sertifikat Pascasarjana. (Bedah Plastik, Brasil),<br
                  class="lg:block hidden">
                Sertifikat Spesialis. (Bedah Plastik, Brasil), TMSBCP (Bedah Plastik, Brasil),<br
                  class="lg:block hidden">
                Spesialis Bedah Plastik Terakreditasi (Departemen Kesehatan, Singapura)
              </p>
              <p class="lg:text-xl text-sitePurple mb-2 text-lg font-bold">Direktur Medis & Ahli Bedah Plastik Kosmetik
              </p>
              <ul class="pl-5 mb-4 list-disc">
                <li class="mb-1">Ahli Bedah Plastik dengan Minat Khusus pada Bedah Plastik Robotik Minimal Invasif</li>
                <li class="mb-1">Pengalaman Internasional di Asia, Amerika, Timur Tengah & Amerika Latin</li>
                <li class="mb-1">Spesialis dalam Pengencangan Perut, Perombakan Mumi & Bedah Plastik Pria</li>
                <li class="mb-1">Pengalaman Lebih Dari 35 Tahun Dalam Bedah Plastik</li>
                <li class="mb-1">Didedikasikan Untuk Kesejahteraan Pasien</li>
                <li class="mb-1">Diberikan oleh 6 Masyarakat Berbeda Untuk Kontribusinya Pada Bedah Endoskopi & Robotik
                </li>
                <li class="mb-1">Memiliki Ketertarikan Tentang <i>Multi-Area Mummy Makeover</i></li>
                <li class="mb-1">Aktif Melakukan Riset & Publikasi Melalui Lokakarya, Jurnal & Konferensi</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 w-full pt-12 pb-8 mx-auto">
        <h3 class="md:mb-5 lg:text-4xl lg:mb-8 mb-4 text-3xl font-semibold leading-tight text-center uppercase">
          Spesialisasi Dr Marco</h3>
        <div class="lg:w-3/4 lg:px-24 w-full px-4 mx-auto text-center">
          <p class="lg:mb-16 lg:px-24 mb-12">
            Dengan pengalaman internasional lebih dari 35 tahun, Dr Marco bangga akan keterampilan bedahnya dan dapat
            membantu pasien dari semua etnis mencapai tujuan estetis mereka.
          </p>
        </div>
        <div class="lg:px-24">
          <div class="lg:-mx-4 lg:px-24 md:mb-12 lg:mb-16 lg:items-center flex flex-wrap mb-8 overflow-hidden">
            <div class="md:w-1/2 lg:order-2 w-full px-4 overflow-hidden">
              <img class="border-sitePPurple lg:ml-auto mb-4 border-b-8" src="images/image-service-01.jpg">
            </div>
            <div class="md:w-1/2 lg:order-1 w-full px-4 overflow-hidden">
              <div class="lg:pr-8">
                <h4 class="lg:mb-6 lg:text-3xl text-sitePurple mb-4 text-xl font-bold uppercase">Wajah & Tubuh</h4>
                <div class="lg:pl-8 border-sitePurple pl-4 border-l">
                  <p class="mb-4">
                    Dr Marco telah mendedikasikan karirnya untuk meningkatkan kepercayaan diri pasien dari seluruh
                    dunia. Dengan berbagai macam perawatan, Dr Marco telah membantu banyak pasien merasa lebih baik
                    tentang wajah dan tubuh mereka. Yakinlah bahwa tidak ada yang lebih penting bagi Dr Marco selain
                    kepuasan Anda.
                  </p>
                  <p class="mb-4">
                    Cari tahu lebih lanjut tentang berbagai prosedur yang diberikan Dr Marco di <a href="bh/services"
                      class="text-sitePurple font-bold">sini.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:px-24">
          <div class="lg:-mx-4 lg:px-24 md:mb-12 lg:mb-16 lg:items-center flex flex-wrap mb-8 overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="border-sitePPurple lg:mr-auto mb-4 border-b-8" src="images/image-service-02.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-3xl text-sitePurple mb-4 text-xl font-bold uppercase">Untuk Pria</h4>
                <div class="lg:pl-8 border-sitePurple pl-4 border-l">
                  <p class="mb-4">
                    Dengan proporsi yang lebih besar dari pria yang sadar tubuh dalam masyarakat modern, Dr Marco telah
                    melihat peningkatan jumlah pria yang mencari penanganan estetis. Untuk memberikan perawatan
                    menyeluruh bagi semua pasien, Dr Marco telah merancang perawatan yang dirancang khusus untuk
                    kebutuhan pria, memungkinkan pria untuk menikmati peningkatan kepercayaan diri dengan keleluasaan
                    maksimal.
                  </p>
                  <p class="mb-4">
                    Cari tahu lebih lanjut tentang perawatan khusus untuk pria di <a href="bh/for-men"
                      class="text-sitePurple font-bold">sini.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:px-24">
          <div class="lg:-mx-4 lg:items-center lg:px-24 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 lg:order-2 w-full px-4 overflow-hidden">
              <img class="border-sitePPurple lg:ml-auto mb-4 border-b-8" src="images/image-service-03.jpg">
            </div>
            <div class="md:w-1/2 lg:order-1 w-full px-4 overflow-hidden">
              <div class="lg:pr-8">
                <h4 class="lg:mb-6 lg:text-3xl text-sitePurple mb-4 text-xl font-bold uppercase">Bedah Plastik Invasif
                  Minimal</h4>
                <div class="lg:pl-8 border-sitePurple pl-4 border-l">
                  <p class="mb-4">
                    Sebagai bagian dari dedikasi Dr Marco untuk perawatan yang aman dan efektif, Dr Marco mengembangkan
                    proyek penelitian yang mengadopsi metode endoskopi untuk operasi plastik pada tahun 1991, dan
                    mengembangkan teknik baru dalam prosedur estetis dan rekonstruktif minimal invasif/lubang kunci. Dia
                    telah meraih beberapa penghargaan internasional atas kontribusinya, dan sejak itu telah menggunakan
                    endoskopi untuk melakukan pengencangan perut, pengecilan/pengangkatan payudara, pembesaran payudara,
                    pengencangan wajah, pengencangan bokong, di antara prosedur lainnya.
                  </p>
                  <p class="mb-4">
                    Cari tahu lebih lanjut tentang perawatan khusus yang cocok untuk bedah endoskopi/robotik di <a
                      href="bh/minimally-invasive-surgery" class="text-sitePurple font-bold">sini.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-8 lg:pt-20 lg:pb-12 max-w-screen-lg pt-12 pb-4 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-12">Dr Marco mengadopsi pendekatan yang mengutamakan pasien dalam perawatannya, dan telah
              membangun kemitraan dengan rumah sakit di seluruh Singapura untuk kenyamanan dan fleksibilitas pasien yang
              maksimal.</p>
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-01.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-02.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-03.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-04.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-05.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-06.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-07.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-08.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-09.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-15.jpg">
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pb-16 max-w-screen-xl pt-0 pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="mb-4" src="images/image-clinic.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <h3 class="md:mb-5 lg:text-4xl lg:mb-6 mb-4 text-3xl font-semibold leading-tight uppercase">Bedah Plastik
                Dr Marco Faria Correa</h3>
              <p class="mb-8">
                Bedah Plastik Dr Marco Faria Correa adalah pusat bedah plastik yang didedikasikan untuk kecantikan yang
                aman dan peningkatan estetis. Dipimpin oleh Dr Marco, ahli bedah plastik terlatih internasional, semua
                perawatan yang diberikan aman dan dirancang untuk hasil yang tampak alami. Dr Marco sangat menekankan
                kepuasan pasien, mendorongnya ke arah inovasi dan memperkenalkan metode modern dan lebih aman ke bidang
                bedah plastik. Ini termasuk metode seperti operasi plastik endoskopi dan pengencangan perut robotik.
              </p>
              <a href="bh/about-us"
                class="bg-sitePurple lg:text-lg inline-block px-10 py-3 mb-4 text-white uppercase">Baca lebih banyak</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <SiteConsultationBH />
    <SiteFormBH />
    <SiteFooterBH />
  </div>
</template>

<style>
  .bg-banner {
    background-image: url(/images/bg-banner.png);
  }
  .bg-banner-2 {
    background-image: url(/images/bg-banner-2.png);
  }
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  @media (min-width: 768px) {

  }
  @media (min-width: 1024px) {

  }
</style>

<script>
  import SiteHeaderBH from '@/components/SiteHeaderBH.vue'
  import SiteFormBH from '@/components/SiteFormBH.vue'
  import SiteConsultationBH from '@/components/SiteConsultationBH.vue'
  import SiteFooterBH from '@/components/SiteFooterBH.vue'
  import { Carousel, Slide } from 'vue-carousel'
  import { CollapseTransition } from 'vue2-transitions'
  export default {
    components: {
      CollapseTransition,
      Carousel,
      Slide,
      SiteHeaderBH,
      SiteConsultationBH,
      SiteFormBH,
      SiteFooterBH
    },
    mounted() {  
      document.title = "Dr Marco Faria Correa Plastic Surgery - Operasi Plastik";  
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>